h1 {
  color: var(--primary);
}

p {
  color: var(--secondary);
}

.container {
  display: flex;
  flex-direction: column;
}

.column {
  margin: 0 auto;
}

img {
  max-width: 100%;
  height: auto;
}